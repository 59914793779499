<template>
  <div class="basic-data-add">
    <el-card>
      <h3 class="title">
        {{ $t('lang.gles.base.deviceInfo') }}
      </h3>
      <m-form
        ref="myForm"
        :form-data="baseFormData"
        label-position="right"
        :label-width="120"
        :extend-config="extendFormConfig"
        :button="button"
        class="basic-data-add-form"
      />
      <el-button type="primary" @click="onCancel">
        {{ $t('lang.gles.common.back') }}
      </el-button>
    </el-card>
    <el-card style="margin-top: 10px">
      <h3 class="title">
        {{ $t('lang.gles.base.deviceGoodsPosition') }}
      </h3>
      <div class="operate-btn">
        <el-button type="text" icon="el-icon-circle-plus" @click="handleAdd">
          {{ $t('lang.gles.base.addDeviceGoodsPosition') }}
        </el-button>
        <el-button
          type="text"
          icon="el-icon-delete-solid"
          class="danger"
          :disabled="!multipleSelectionList.length"
          @click="handleBatchDelete"
        >
          {{ $t('lang.gles.common.batchDelete') }}
        </el-button>
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :extend-config="tableExtendConfig"
        :page-data="pageData"
        max-height="440px"
        @editItem="editItem"
        @delItem="delItem"
        @pageChange="pageChange"
        @selection-change="handleSelectionChange"
      />
    </el-card>
    <!-- 添加/编辑设备货位 -->
    <edit-dialog
      v-if="dialogVisible"
      ref="myForm"
      :visible.sync="dialogVisible"
      :form-data="dialogFormData"
      :row-detail.sync="goodsPositionRowDetail"
      :mode="mode"
      @reset="dialogVisible = false"
      @submit="handleSubmit"
    >
      <template #inputCodeTipIcon>
        <el-tooltip
          effect="dark"
          :content="$t('批量添加的数量，最多10个')"
          placement="top"
        >
          <i class="el-icon-tip el-icon-question" style="margin-left: 5px" />
        </el-tooltip>
      </template>
    </edit-dialog>
  </div>
</template>
<script>
import MForm from '@/libs_sz/components/MForm/MForm'
import {
  getEditBaseFormData,
  getGoodsPositionTableItem,
  getEditGoodsPositionFormData
} from '../data'
import addMixins from '@/mixins/addMixins'
import commonMixins from '@/mixins/commonMixins'
import EditDialog from './editDialog.vue'
import { mapState } from 'vuex'

export default {
  name: 'DeviceGoodsPosition',
  components: {
    MForm,
    EditDialog
  },
  mixins: [addMixins, commonMixins],
  props: {
    rowDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isGoodsPosition: true,
      tableExtendConfig: {
        sortNum: false,
        checkBox: true,
        maxHeight: '350px',
        operateWidth: '150px',
        operate: [
          {
            event: 'editItem',
            label: this.$t('lang.gles.common.edit')
          },
          {
            event: 'delItem',
            confirm: true,
            confirmMessage: this.$t('lang.gles.common.deleteConfirmMsg0'),
            label: this.$t('lang.gles.common.delete'),
            customClass: 'danger'
          }
        ]
      },
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      },
      tableData: [],
      dialogVisible: false,
      mode: 'add',
      goodsPositionRowDetail: {},
      multipleSelectionList: [],
      formModel: {},
      totalGoodsPositionVoList: []
    }
  },
  computed: {
    ...mapState('base', ['abAxisConnectList']),
    baseFormData() {
      return getEditBaseFormData(this, { ...this.rowDetail })
    },
    tableItem() {
      return getGoodsPositionTableItem(this)
    },
    dialogFormData() {
      return getEditGoodsPositionFormData(this, {
        ...this.goodsPositionRowDetail
      })
    }
  },
  created() {
    this.getDetail()
    this.goodsPositionRowDetail.deviceId = this.rowDetail.id
    this.getFactoryList()
    this.getWorkshopList()
    this.getAreaList()
    this.getProductionLineList()
    if (!this.abAxisConnectList.length) {
      this.$store.dispatch('base/getAbAxisConnectList')
    }
  },
  methods: {
    async getDetail() {
      const { data, code } = await this.$httpService(
        this.$apiStore.base('queryDeviceDetail'),
        { id: this.rowDetail.id }
      )
      if (code) return
      this.totalGoodsPositionVoList = data.goodsPositionVoList || []
      this.pageData = {
        ...this.pageData,
        recordCount: this.totalGoodsPositionVoList?.length
      }
      this.tableData = this.getCurrentPageData()
    },
    getCurrentPageData() {
      const { currentPage, pageSize } = this.pageData
      return (
        this.totalGoodsPositionVoList.filter((item, i) => {
          const startIdx = (currentPage - 1) * pageSize
          const endIdx = currentPage * pageSize
          const idx = i + 1
          return idx > startIdx && idx <= endIdx
        }) ?? []
      )
    },
    /**
     * 分页切换
     */
    pageChange(pageData) {
      this.pageData = { ...this.pageData, ...pageData }
      this.tableData = this.getCurrentPageData()
    },
    /**
     * 添加
     */
    handleAdd() {
      this.mode = 'add'
      this.dialogVisible = true
      this.goodsPositionRowDetail = {
        deviceId: this.rowDetail.id
      }
    },
    /**
     * 批量删除
     */
    async handleBatchDelete() {
      // 二次确认
      this.$confirm(this.$t('lang.gles.common.deleteConfirmMsg0'), '', {
        confirmButtonText: this.$t('lang.gles.common.confirm'),
        cancelButtonText: this.$t('lang.gles.common.cancel'),
        type: 'warning'
      }).then(() => {
        this.batchDelete()
      })
    },
    async batchDelete() {
      const delIds = this.multipleSelectionList.map((item) => item.id)
      const { code } = await this.$httpService(
        this.$apiStore.base('deleteGoodsPositionByIds'),
        { ids: delIds }
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
      await this.getDetail()
      this.resetPageData()
    },
    /**
     * 多选货位
     */
    handleSelectionChange(val) {
      this.multipleSelectionList = val
    },
    /**
     * 编辑设备货位
     */
    editItem({ row }) {
      this.mode = 'edit'
      this.dialogVisible = true
      this.goodsPositionRowDetail = {
        ...this.goodsPositionRowDetail,
        ...row
      }
    },
    /**
     * 删除设备货位
     */
    async delItem({ row }) {
      const { code } = await this.$httpService(
        this.$apiStore.base('deleteGoodsPosition'),
        { id: row.id }
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
      await this.getDetail()
      this.resetPageData()
    },
    resetPageData() {
      if (
        this.totalGoodsPositionVoList?.length <=
        (this.pageData.currentPage - 1) * this.pageData.pageSize
      ) {
        this.pageData.currentPage = 1
        this.tableData = this.getCurrentPageData()
      }
    },
    /**
     * 批量新增
     */
    async getBatchGoodsPosition(model) {
      const {
        data: { startNum = 1, endNum = 1 },
        code
      } = await this.$httpService(this.$apiStore.base('getOrderNumber'), {
        numberLength: model.batchAddNum - 1,
        bitType: 'goodsPositionSerialNum'
      })
      if (code) return
      const list = []
      for (let i = startNum + 1; i <= endNum; i++) {
        const serialNum = this.gegeSerialnumber(i)
        list.push({
          goodsPositionCode: `DP${serialNum}`,
          goodsPositionType: model.goodsPositionType,
          deviceId: model.deviceId,
          abAxisConnect: model.abAxisConnect,
          goodsPositionDescription: model.goodsPositionDescription
        })
      }
      return list
    },
    /**
     * 前端根据后端返回自增序号  生成编码
     */
    gegeSerialnumber(i) {
      let numStr = i + ''
      let diff = 8 - numStr.length
      while (diff) {
        numStr = `0${numStr}`
        diff--
      }
      return numStr
    },
    /**
     * 添加货位保存
     */
    async handleSubmit() {
      // 先校验
      this.$refs.myForm.getValidateFormModel().then(async (model) => {
        const copyModel = { ...model }
        delete copyModel.batchAddFlag
        const params = {
          goodsPositionList: [copyModel]
        }
        if (model.batchAddFlag) {
          const list = await this.getBatchGoodsPosition(model)
          params.goodsPositionList = [...params.goodsPositionList, ...list]
        }
        this.mode === 'add'
          ? await this.insert(params)
          : await this.update({ ...model })
      })
    },
    /**
     * 新增
     */
    async insert(params) {
      const { code } = await this.$httpService(
        this.$apiStore.base('insertDevice'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.addSuccessfully'))
      this.dialogVisible = false
      this.getDetail()
    },
    /**
     * 编辑
     */
    async update(model) {
      const params = {
        ...model,
        id: this.goodsPositionRowDetail.id,
        goodsPositionDescription: model.goodsPositionDescription || '',
        goodsPositionCode: model.goodsPositionCode || ''
      }
      const { code } = await this.$httpService(
        this.$apiStore.base('updateGoodsPosition'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.updateSuccessfully'))
      this.dialogVisible = false
      this.getDetail()
    },
    /**
     * 取消 新增/编辑
     */
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'deviceList'
      })
    }
  }
}
</script>

<template>
  <div class="basic-data-list">
    <query-view
      ref="myQueryView"
      name="codeOrName"
      :value.sync="baseFormModel.codeOrName"
      :placeholder="$t('lang.gles.common.pleaseInputCodeOrName')"
      :query-list="moreQueryData"
      @baseSearch="handleBaseSearch"
      @moreSearch="handleMoreSearch"
    />
    <el-card class="table-card">
      <div class="operator-wrapper">
        <el-button type="primary" icon="el-icon-plus" @click="updateCom">
          {{ $t('lang.gles.common.addNew') }}
        </el-button>
        <!-- 列设置 -->
        <column-config :list.sync="columnConfigList" />
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
        @editItem="editItem"
        @delItem="delItem"
        @deviceGoodsPositionItem="handleDeviceGoodsPosition"
        @pageChange="pageChange"
      />
    </el-card>
  </div>
</template>
<script>
import ColumnConfig from '@/components/columnConfig'
import QueryView from '@/components/moreQuery/queryView.vue'
import MTable from '@/libs_sz/components/MTable/MTable'
import { getMoreQueryFormData, getSearchTableItem } from '../data'
import * as types from '@/store/mutationTypes'
import listMixins from '@/mixins/listMixins'
import commonMixins from '@/mixins/commonMixins'

export default {
  name: 'DeviceList',
  components: {
    ColumnConfig,
    QueryView,
    MTable
  },
  mixins: [listMixins, commonMixins],
  data() {
    return {
      tableData: [],
      formModel: {},
      baseFormModel: {}
    }
  },
  computed: {
    moreQueryData() {
      return getMoreQueryFormData(this, { ...this.formModel })
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      return getSearchTableItem(this)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter((item, i) => item.name === this.columnConfigList[i]?.name && this.columnConfigList[i]?.isShow)
      }
      return list
    }
  },
  async created() {
    this.tableExtendConfig = {
      ...this.tableExtendConfig,
      operateWidth: '180px',
      operate: [
        {
          event: 'deviceGoodsPositionItem',
          label: this.$t('lang.gles.workflow.deviceGoodsPosition')
        },
        ...this.tableExtendConfig.operate
      ]
    }
    this.doSearch(this.baseFormModel)
    this.initSelectList()
  },
  methods: {
    /**
     * 初始化下拉列表
     */
    initSelectList() {
      this.getFactoryList()
      this.getWorkshopList()
      this.getAreaList()
      this.getProductionLineList()
    },
    // 查询
    async doSearch(params, api = 'getDeviceListByCodeOrName') {
      try {
        const { data, code } = await this.$httpService(this.$apiStore.base(api, this.pageData), params)
        if (code) return
        this.$store.commit(types.IS_LOADING, false)
        const { recordList = [], currentPage, pageSize, totalRecordCount: recordCount } = data || {}
        this.pageData = { currentPage, pageSize, recordCount }
        this.tableData = recordList || []
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    /**
     * 所属工厂 change
     * 工厂-车间
     */
    handleFactoryChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      this.formModel.workshopId = null
      this.formModel.areaId = null
      this.formModel.productionLineId = null
      this.getWorkshopList({ factoryId: this.formModel.factoryId })
      this.getAreaList({})
      this.getProductionLineList({})
    },
    /**
     * 所属车间 change
     * 车间-区域
     */
    handleWorkshopChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      this.formModel.AreaId = null
      this.formModel.productionLineId = null
      this.getAreaList({ workshopId: this.formModel.workshopId })
      this.getProductionLineList({})
    },
    /**
     * 所属区域 change
     * 区域-产线
     */
    handleAreaChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      this.formModel.productionLineId = null
      this.getProductionLineList({ areaId: this.formModel.areaId })
    },
    /**
     * 基础查询
     */
    handleBaseSearch(data) {
      this.doSearch({ ...data })
    },
    /**
     * 更多查询
     */
    handleMoreSearch(data) {
      this.formModel = { ...data }
      this.doSearch({ ...data }, 'getDeviceList')
    },
    /**
     * 新增
     */
    updateCom({ row }) {
      this.$emit('updateCom', {
        currentCom: 'DeviceAdd',
        mode: 'add',
        row
      })
    },
    /**
     * 编辑
     */
    editItem({ row }) {
      this.$emit('updateCom', {
        currentCom: 'DeviceAdd',
        mode: 'edit',
        row
      })
    },
    /**
     * 删除
     */
    async delItem({ row }) {
      const deleteFlag = await this.deleteDeviceAssert(row)
      if (deleteFlag) {
        this.$message.error(this.$t('删除失败，设备存在货位数据'))
        return
      }
      const { code } = await this.$httpService(this.$apiStore.base('deleteDevice'), { id: row.id, deviceId: row.deviceId })
      if (code) return
      this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
      if (this.tableData?.length < 2) {
        this.pageData.currentPage = 1
      }
      this.doSearch(this.baseFormModel)
    },
    /**
     * 判断是否存在关联货位数据
     */
    async deleteDeviceAssert(row) {
      const { data, code } = await this.$httpService(this.$apiStore.base('deleteDeviceAssert'), { id: row.id, deviceId: row.deviceId })
      if (code) return
      return data
    },
    /**
     * 设备货位
     */
    handleDeviceGoodsPosition({ row }) {
      this.$emit('updateCom', {
        currentCom: 'DeviceGoodsPosition',
        mode: 'edit',
        row
      })
    }
  }
}
</script>

<template>
  <div class="container-type">
    <component :is="currentCom" :mode="mode" :row-detail.sync="row" @updateCom="updateCom" @update:row-detail="rowDetail = $event" />
  </div>
</template>
<script>
import DeviceList from './components/list.vue'
import DeviceAdd from './components/add.vue'
import DeviceGoodsPosition from './components/goodsPosition.vue'
export default {
  components: {
    DeviceList,
    DeviceAdd,
    DeviceGoodsPosition
  },
  data() {
    return {
      currentCom: 'DeviceList',
      mode: '',
      row: {}
    }
  },
  methods: {
    updateCom({ currentCom, mode, row = {}}) {
      this.currentCom = currentCom
      this.mode = mode
      this.row = row
    }
  }
}
</script>
<style lang="scss" scoped></style>

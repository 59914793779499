import mapVerify from '@/utils/formValidate'
const getFormatter = (data, value) => {
  return data?.find((t) => t.value === value)?.label ?? value
}
// 获取更多查询字段列表
export const getMoreQueryFormData = (that, formModel) => {
  const moreQueryData = [
    // 设备编码
    {
      name: 'deviceCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.deviceCode')
    },
    // 设备名称
    {
      name: 'deviceName',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.deviceName')
    },
    // 所属工厂
    {
      name: 'factoryId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingFactory'),
      filterable: true,
      data: that.factoryList,
      mchange: that.handleFactoryChange
    },
    // 所属车间
    {
      name: 'workshopId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingWorksop'),
      filterable: true,
      data: that.workshopList,
      mchange: that.handleWorkshopChange
    },
    // 所属区域
    {
      name: 'areaId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingArea'),
      filterable: true,
      data: that.areaList,
      mchange: that.handleAreaChange
    },
    // 所属产线
    {
      name: 'productionLineId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingProductionLine'),
      filterable: true,
      data: that.productionLineList
    }
  ]
  moreQueryData.forEach((item) => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return moreQueryData
}
// 查询列表列表
export const getSearchTableItem = (that) => {
  const searchTableItem = [
    // 设备编码
    {
      prop: 'deviceCode',
      label: that.$t('lang.gles.base.deviceCode'),
      isShow: true,
      minWidth: 120,
      sortable: true
    },
    // 设备名称
    {
      prop: 'deviceName',
      label: that.$t('lang.gles.base.deviceName'),
      isShow: true
    },
    {
      prop: 'factoryName',
      label: that.$t('lang.gles.base.belongingFactory'),
      isShow: true
    },
    {
      prop: 'workShopName',
      label: that.$t('lang.gles.base.belongingWorksop'),
      isShow: true
    },
    {
      prop: 'areaName',
      label: that.$t('lang.gles.base.belongingArea'),
      isShow: true
    },
    // 所属产线
    {
      prop: 'productionLineName',
      label: that.$t('lang.gles.base.belongingProductionLine'),
      isShow: true
    },
    // 设备描述
    {
      prop: 'deviceDescription',
      label: `${that.$t('lang.gles.base.deviceDesc')}`,
      isShow: true
    }
  ]
  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))
  return searchTableItem
}

// 编辑弹框 基础信息
export const getEditBaseFormData = (that, row) => {
  const baseFormData = [
    // 设备编码
    {
      name: 'deviceCode',
      value: row.deviceCode || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.deviceCode'),
      rules: mapVerify(['required', 'inputCode']),
      appendSlotName: 'inputCodeTipIcon',
      class: 'tip-icon'
    },
    // 设备名称
    {
      name: 'deviceName',
      value: row.deviceName || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.deviceName'),
      placeholder: that.$t('lang.gles.common.inputLen200'),
      rules: mapVerify(['required', 'inputLen200'])
    },
    // 所属工厂
    {
      name: 'factoryId',
      value: row.factoryId || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingFactory'),
      rules: mapVerify(['required']),
      filterable: true,
      data: that.factoryList,
      mchange: that.handleFactoryChange
    },
    // 所属车间
    {
      name: 'workshopId',
      value: row.workshopId || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingWorksop'),
      rules: mapVerify(['required']),
      filterable: true,
      data: that.workshopList,
      mchange: that.handleWorkshopChange
    },
    // 所属区域
    {
      name: 'areaId',
      value: row.areaId || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingArea'),
      rules: mapVerify(['required']),
      filterable: true,
      data: that.areaList,
      mchange: that.handleAreaChange
    },
    // 所属产线
    {
      name: 'productionLineId',
      value: row.productionLineId || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingProductionLine'),
      rules: mapVerify(['required']),
      filterable: true,
      data: that.productionLineList
    },
    // 设备描述
    {
      name: 'deviceDescription',
      value: row.deviceDescription || '',
      span: 24,
      component: 'elInput',
      type: 'textarea',
      rows: that.isGoodsPosition ? 2 : 6,
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.deviceDesc'),
      rules: mapVerify(['inputLen500'])
    }
  ]
  // 设备货位
  baseFormData.forEach((item) => {
    if (that.isGoodsPosition) {
      item.span = 6
      item.disabled = true
    }
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return baseFormData
}

// 设备货位列表
export const getGoodsPositionTableItem = (that) => {
  const searchTableItem = [
    // 货位编码
    {
      prop: 'goodsPositionCode',
      label: that.$t('lang.gles.base.goodsPositionCode'),
      isShow: true,
      minWidth: 140
    },
    // A/B轴对接
    {
      prop: 'abAxisConnect',
      label: that.$t('lang.gles.base.abAxisConnect'),
      isShow: true,
      minWidth: 140,
      formatter(row, column) {
        return getFormatter(that.abAxisConnectList, row[column])
      }
    },
    // 货位描述
    {
      prop: 'goodsPositionDescription',
      label: `${that.$t('lang.gles.base.goodsPositionDescription')}`,
      isShow: true,
      showOverflowTooltip: true
    }
  ]

  return searchTableItem
}

export const getEditGoodsPositionFormData = (that, row) => {
  const baseFormData = [
    // 货位编码
    {
      name: 'goodsPositionCode',
      value: row.goodsPositionCode || '',
      span: that.mode === 'add' ? 12 : 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.goodsPositionCode'),
      placeholder: that.$t('50个字符以内，支持字母、数字、‘-’字符组合'),
      rules: mapVerify(['required', 'inputCode']),
      isShow: true
    },
    // 批量添加
    {
      name: 'batchAddNum',
      value: row.batchAddNum || '',
      labelType: 'checkbox',
      span: 12,
      component: 'elInput',
      min: 1,
      max: 10,
      controls: false,
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.common.batchAdd'),
      placeholder: '请输入批量添加的数量，最多10个',
      disabled: !row.batchAddFlag,
      isShow: that.mode === 'add',
      rules: mapVerify(['maxNum10'])
    },
    {
      name: 'batchAddFlag',
      value: row.batchAddFlag || '',
      isShow: that.mode === 'add'
    },
    {
      name: 'goodsPositionType',
      value: 'deviceGoodsPositionSec',
      isShow: true
    },
    {
      name: 'deviceId',
      value: row.deviceId || '',
      isShow: true
    },
    // A/B轴
    {
      name: 'abAxisConnect',
      value: row.abAxisConnect ?? 0,
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: true,
      label: that.$t('lang.gles.base.abAxisConnect'),
      isShow: true,
      rules: mapVerify(['required']),
      data: that.abAxisConnectList
    },
    // 货位描述
    {
      name: 'goodsPositionDescription',
      value: row.goodsPositionDescription || '',
      span: 24,
      component: 'elInput',
      type: 'textarea',
      rows: 6,
      adaptionw: true,
      showWordLimit: true,
      label: that.$t('lang.gles.base.goodsPositionDescription'),
      isShow: true,
      rules: mapVerify(['inputLen500'])
    }
  ]

  /*   // 设备货位
  if (that.isGoodsPosition) {
    baseFormData.forEach(item => {
      item.span = 6
      item.disabled = true
    })
  } */
  return baseFormData.filter((item) => item.isShow)
}

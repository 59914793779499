<template>
  <m-dialog
    width="60%"
    class="ui-detailDialog"
    :visible.sync="visible"
    :is-need-footer="false"
    :title="title"
    @closed="$emit('update:visible', false)"
  >
    <el-form
      ref="formBox"
      :model="formModel"
      class="Mform"
      label-position="left"
    >
      <el-row :gutter="24" type="flex" class="autoWrap">
        <el-col
          v-for="item in formData.filter((item) => item.component)"
          :key="item.name"
          :span="item.span"
        >
          <el-form-item
            :prop="item.name"
            :rules="item.rules"
            :label-width="item.labelWidth"
          >
            <template #label>
              <el-checkbox
                v-if="item.labelType === 'checkbox'"
                v-model="formModel.batchAddFlag"
                @change="handleBatchAdd"
              >
                {{ item.label }}
              </el-checkbox>
              <span v-else class="ell" :title="item.label">{{
                item.label
              }}</span>
            </template>
            <template v-if="!!item.component">
              <component
                :is="item.component"
                v-model="formModel[item.name]"
                autocomplete="off"
                v-bind="item"
                :disabled="
                  typeof item.disabled === 'function'
                    ? item.disabled(formModel)
                    : item.disabled
                "
                v-on="item"
                @blur="(event) => _formBlur(event, item)"
                @change="(val) => _formChange(val, item)"
              >
                <!-- 针对select组件 -->
                <template v-if="item.component === 'elSelect'" #default>
                  <el-option
                    v-for="(children, num) in itemData(item)"
                    :key="num"
                    v-bind="children"
                    :label="children.label"
                    :value="children.value"
                  />
                </template>
              </component>
            </template>
            <template v-else>
              <slot name="appendComponent" :item="item">
                <span>{{ formModel[item.name] }}</span>
              </slot>
            </template>
            <!-- 扩展添加 -->
            <slot
              v-if="item.appendSlotName"
              :name="item.appendSlotName"
              :formModel="formModel"
              :item="item"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24" type="flex" justify="end">
        <el-col :span="24" class="tc">
          <el-form-item class="nowrap">
            <el-button class="w70" @click="resetForm">
              {{ $t('lang.gles.common.cancel') }}
            </el-button>
            <el-button
              type="primary"
              class="ui-sureBtn pl10 pr10"
              :loading="submitLoading"
              @click="submitForm"
            >
              {{ $t('lang.gles.common.confirm') }}
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </m-dialog>
</template>
<script>
import addMixins from '@/mixins/addMixins'
import { mapState } from 'vuex'
export default {
  mixins: [addMixins],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      submitLoading: false,
      batchAddFlag: false,
      formModel: {},
      title:
        this.mode === 'add'
          ? this.$t('lang.gles.base.addDeviceGoodsPosition')
          : this.$t('lang.gles.base.EditDeviceGoodsPosition')
    }
  },
  computed: {
    ...mapState('base', ['abAxisConnectList'])
  },
  watch: {
    formData() {
      this._initFormValue()
    }
  },
  created() {
    this._initFormValue()
    if (this.mode === 'add') {
      this.getSerialBizCode('goodsPositionSerialNum', 'goodsPositionCode')
    }
    if (!this.abAxisConnectList?.length) {
      this.$store.dispatch('base/getAbAxisConnectList')
    }
  },
  methods: {
    /**
     * 校验批量添加数量
     * 1-10的正整数
     */
    checkBatchAddNum(keyEvent, formItem) {
      console.log(',mmm', keyEvent)
    },
    handleBatchAdd() {
      this.$emit('update:rowDetail', this.formModel)
    },
    // 获取 item.data
    itemData(item) {
      return typeof item.data === 'function'
        ? item.data(this.formModel)
        : item.data || []
    },
    // 重置表单
    resetForm() {
      this.clearValidate()
      this.$emit('reset', {})
    },
    // 清除当前验证
    clearValidate() {
      this.$refs['formBox'].resetFields()
    },
    // 提交表单
    submitForm() {
      this.submitLoading = true
      this.$refs['formBox'].validate((valid) => {
        if (valid) {
          this.$emit('submit', this._filterFormModel())
          this.$emit('update:visible', false)
        }
        setTimeout(() => {
          this.submitLoading = false
        }, 500)
      })
    },
    // 赋值初始值
    _initFormValue() {
      const value = {}
      if (Array.isArray(this.formData)) {
        this.formData.map((item) => (value[item.name] = item.value))
        this.formModel = value
        console.log(this.formModel)
      }
    },
    _filterFormModel() {
      const newFormModel = {}
      Object.keys(this.formModel).forEach((item) => {
        const val = this.formModel[item]
        if (
          String(val) &&
          String(val) !== 'null' &&
          String(val) !== 'undefined'
        ) {
          newFormModel[item] = val
        }
      })
      return newFormModel
    },
    /** 代理blur事件 */
    _formBlur(event, formItem) {
      const { name, component } = formItem
      component &&
        ['elInput', 'el-input'].includes(String(component)) &&
        (this.formModel[name] = event.target.value.trim())
      if ('blur' in formItem || 'onBlur' in formItem) {
        formItem.blur && formItem.blur(event)
        formItem.onBlur && formItem.onBlur(event)
      }
    },
    /**
     *  代理change方法
     */
    _formChange(val, formItem) {
      console.log('sd', val)
      const { change, mchange } = formItem
      if (change && !mchange) return
      mchange && mchange(val, formItem, this.changeFormValue, this.formModel)
    },
    /**
     *  代理change方法
     */
    _handleInput(val, formItem) {
      console.log('val', val)
      const { input, mInput } = formItem
      if (input && !mInput) return
      mInput && mInput(val, formItem, this.changeFormValue, this.formModel)
    },
    /**
     *  代理change方法
     */
    _handleKeyUp(keyEvent, formItem) {
      const { keyup, name, type } = formItem
      // textarea 需要处理分行功能，tirm会过滤分行
      if (keyEvent.keyCode === 13 && type === 'textarea') {
        this.formModel[name] = this.formModel[name] + '\n'
      }
      keyup && keyup(keyEvent, formItem, this.changeFormValue, this.formModel)
    },
    // 获取合法参数
    async getValidateFormModel(isFilter = true) {
      try {
        const valid = await this.$refs['formBox'].validate()
        return valid
          ? Promise.resolve(isFilter ? this._filterFormModel() : this.formModel)
          : Promise.reject()
      } catch (e) {
        return Promise.reject()
      }
    },
    async validateFlag(isFilter = true) {
      const valid = await this.$refs['formBox'].validate()
      return valid
    }
  }
}
</script>
